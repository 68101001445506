var api = "https://api.qingcigame.com/web";

var index = '';
var go_page = window.location.href.split('?page=')[1];

var initialSlide = 0; //设置默认索引为0
var num = 0; //当前播放歌曲的下标
var music = new Howl({
    src: ["//download4.qcplay.com/qcgame-admin/wNiFjUOf9mrfu6Tq2D9vjxbwLcGcPSTU80sZJa5w.mp3"],
    autoplay: true,
    loop: true,
    onplay: function() {
        $('.h-music-box').addClass('music-on'); //开始播放样式
    },
});
var musicCount = 0;

'<input id="link ' + num + ' ">'


var obj = {
    index: function() {
        function getRequest() {
            var query = window.location.search;
            if (query.indexOf("?") != -1) {
                var str = query.substring(1);
                var strs = str.split("=");
                if (strs[1] == 1) {
                    initialSlide = 1;
                } else if (strs[1] == 2) {
                    initialSlide = 2;
                } else if (strs[1] == 3) {
                    initialSlide = 3;
                } else if (strs[1] == 4) {
                    initialSlide = 4;
                } else if (strs[1] == 5) {
                    initialSlide = 5;
                } else if (strs[1] == 6) {
                    initialSlide = 6;
                }
            }
        };
        getRequest(); //二级页面导航栏跳转
        this.musicPlay(); //音乐播放
        this.preloading(); // 预加载
        this.indexAjax(); // 首页接口请求
        this.jqslideTxtBox(); // 游戏咨询信息切换
        // this.Textlength(28, 25); // 内容字体长度超过40，标题字体长度超过25
        this.videoAndImg(); // 视频和图片预览
        this.indexBind(); // 首页按钮
        this.newsBanner(); //新闻轮播
        // this.countNum(); //阿里云埋点
    },
    // 新闻列表
    newsList: function() {
        this.newsZxList(); // 新闻最新列表
        this.newsTabPage(); // 最新、综合、活动跳转
        this.musicPlay(); //音乐播放
    },
    // 新闻详情
    newsDetails: function() {
        this.newsInfo();
        this.scroll();
        this.musicPlay(); //音乐播放
    },
    // 视听回廊
    galleryList: function() {
        this.jqslideTxtBox(); // 游戏咨询信息切换
        this.videoSwiper(); //视频轮播
        this.wookmarkBind(); // 瀑布流图片
        // this.galleryBind(); //按钮事件
        this.galleryAjax(); //视听回廊接口
    },
    // 游戏攻略列表
    intrList: function() {
        this.newsIntrList(); // 新闻最新列表
        this.newsTabPage(); // 最新、综合、活动跳转
    },
    // 视频中心
    videoCenter: function() {
        this.videoCenterAjax(); // 获取视频中心视频
        this.videoSwitch(); // 选择播放的视频
    },
    // 视频详情
    videoDetails: function() {
        this.videoDetailsAjax(); // 获取详细的视频
    },
    // 首页按钮
    indexBind: function() {
        // 百度品专taptap特殊处理
        var query = window.location.search;
        if (query.indexOf('type=bdpz') != -1) {
            $('.taptap-btn').remove();
            $('.taptap-gz-btn').remove();
            $('.bottom-code-box').addClass('bdpz')
        }

        $('.monitor-btn').on('click', function() {
            var mon_btn = $(this).attr('data-event');
            obj.countNum(mon_btn);
        })

        // 安卓下载链接暂未开放
        // $('.and-btn').click(function(event) {
        //     layer.msg("敬请期待", { time: 2000 });
        // });

        // 视频播放
        var Media = document.getElementById('media-play');
        $('.video-btn').click(function() {
            music.pause();
            musicCount = 1;
            $('.h-music-box').addClass('ani-pause');

            var _this = $(this);
            var data = _this.attr('data');
            $('.play-popup').fadeIn().find('video').attr('src', data);
            Media.play();
        });

        // 关闭视频
        $('.video-pop').click(function(event) {
            $('.popup').fadeOut().find('video').attr('src', '');
            Media.pause();

            music.play();
            $('.h-music-box').removeClass('ani-pause'); //去除暂时播放样式
            $('.h-music-box').addClass('music-on'); //开始播放样式
            musicCount = 0;
        });

        // 关闭弹窗
        $('.popup').on('click', '.close-btn', function(event) {
            $('.popup').fadeOut();
        });

        // 关闭弹窗
        $('.popup').on('click', function(event) {
            $('.popup').fadeOut();
        });

        // 微信关注弹窗
        $('.wx-gz-btn, .weixin-btn').click(function(event) {
            $('.float-nav').removeClass('nav-show');
            $('.wx-gz-popup').fadeIn();
        });

        // 微博关注弹窗
        $('.wb-gz-btn').click(function(event) {
            $('.float-nav').removeClass('nav-show');
            $('.wb-gz-popup').fadeIn();
        });

        // 抖音关注弹窗
        $('.dy-gz-btn').click(function(event) {
            $('.float-nav').removeClass('nav-show');
            $('.dy-gz-popup').fadeIn();
        });

        // taptap关注弹窗
        $('.taptap-gz-btn').click(function(event) {
            $('.float-nav').removeClass('nav-show');
            $('.tt-gz-popup').fadeIn();
        });

        // qq群关注弹窗
        $('.qq-gz-btn').click(function(event) {
            $('.float-nav').removeClass('nav-show');
            $('.qq-gz-popup').fadeIn();
        });

        // 小红书关注弹窗
        $('.xhs-gz-btn').click(function(event) {
            $('.float-nav').removeClass('nav-show');
            $('.xhs-gz-popup').fadeIn();
        });


        // 游戏攻略切换
        $(".intro li").on('click', function() {
            $(this).addClass("active").siblings().removeClass("active");
        });

        // 仙剑音画【壁纸】
        $(".wallpaper-box .item img").on('click', function() {
            var _this = $(this);
            var img_url = _this.attr('src');
            $('.wallpaper-wrap').fadeIn();
            $('.wallpaper-wrap img').attr("src", img_url);
        });

        $('.wallpaper-wrap').on('click', '.close-btn', function(event) {
            $('.popup').fadeOut();
            setTimeout(() => {
                $('.wallpaper-wrap img').attr("src", '');
            }, 300)
        });

        // $('.download-box').on('click', 'a', function() {
        //     var className = $(this).attr('class');
        //     // var game_id = 74;
        //     var query = window.location.search;
        //     // $.ajax({
        //     //     type: "get",
        //     //     url: 'https://mapi.qingcigame.com/get_url?game_id=' + game_id + '',
        //     //     dataType: 'json',
        //     //     xhrFields: {
        //     //         withCredentials: true
        //     //     },
        //     //     success: function(json) {
        //     // 百度品专 专属下载地址
        //     if (query.indexOf('type=bdpz') != -1) {
        //         if (className.indexOf('app-btn') !== -1) {
        //             // ios
        //             location.href = "https://ad.qingcigame.com/v1/ad-click?game_id=74&os=iOS&company=qc&channel=kol&tag=1115251&optimizer_id=19&redirct=https://apps.apple.com/cn/app/%E9%AD%94%E5%8D%A1%E5%B0%91%E5%A5%B3%E6%A8%B1-%E5%9B%9E%E5%BF%86%E9%92%A5%E5%8C%99/id6450409747";
        //         } else {
        //             // 安卓
        //             location.href = "https://download4.qcplay.com/apk-v2/qc-kol-1111149.apk";
        //         }
        //     } else {
        //         if (className.indexOf('app-btn') !== -1) {
        //             // ios
        //             // if (json.data.ios_down_url == '' || json.data.ios_down_url == null) {
        //             //     layer.msg('ios下载暂未开放，敬请期待！', { time: 3000 });
        //             // } else {
        //             //     location.href = json.data.ios_down_url
        //             // }
        //             location.href = '//apps.apple.com/cn/app/%E9%AD%94%E5%8D%A1%E5%B0%91%E5%A5%B3%E6%A8%B1-%E5%9B%9E%E5%BF%86%E9%92%A5%E5%8C%99/id6450409747'
        //         } else {
        //             // 安卓
        //             // if (json.data.android_down_url == '' || json.data.android_down_url == null) {
        //             // layer.msg('安卓下载暂未开放，敬请期待！', { time: 3000 });
        //             // } else {
        //             location.href = 'https://download4.qcplay.com/apk-v2/qc-kol-1115236.apk';
        //             // }
        //         }
        //     }

        //     // }
        //     // });
        // });
    },
    // 网站音乐
    musicPlay: function() {
        music.play();

        // 点击音乐
        $('.h-music-box').click(function(event) {
            if (musicCount == 1) {
                music.play();
                $(this).removeClass('ani-pause'); //去除暂时播放样式
                $(this).addClass('music-on'); //开始播放样式
                musicCount = 0;
            } else {
                $(this).addClass('ani-pause') //添加暂时播放样式
                music.pause();
                musicCount = 1;
            }
        });
    },
    newsBanner: function() {
        // index = layer.load(2, { shade: [0.1, '#fff'] });
        $.ajax({
            url: "https://api.qingcigame.com/web/news/info",
            type: "GET",
            data: {
                game_id: 74
            },
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    var article = json.data.article;
                    $.each(article.game_information, function(index, values) {
                        var gameInfoIndex = `<div class="swiper-slide swiper-no-swiping">
                        <a  href="${values.jump_url}">
                        <img src="${values.pc_image}">
                        <span></span>
                        </a>
                        </div>`;
                        $('.swiper-container-news .swiper-wrapper').append(gameInfoIndex);
                    });


                } else {
                    layer.msg("服务器请求失败", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("服务器请求失败", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    showSwiper: function() {
        var showSwiper = new Swiper('.swiper-container-show', {
            loop: true,
            direction: 'vertical',
            paginationClickable: true,
            // grabCursor: true,
            centeredSlides: true,
            slidesPerView: 'auto',
            // noSwiping: true,
            autoplay: 2000,
            spaceBetween: 100,
            autoplayDisableOnInteraction: false,
            navigation: {
                nextEl: '.swiper-button-next2',
                prevEl: '.swiper-button-prev2',
            },
            speed: 500,
            on: {
                init: function(swiper) {
                    swiperAnimate(this);
                    // swiper.stopAutoplay();

                    function Ani(btn, page) {
                        $(btn).on('click', function() {
                            showSwiper.slideTo(page, 500, true);
                            $(this).parent().addClass('select').siblings().removeClass("select")
                        });
                    };

                    function showLink() {
                        for (var i = 4; i >= 0; i--) {
                            Ani('.show-click-box .s' + i, i - 1);
                        };
                    }
                    showLink();

                },
                slideChangeTransitionStart: function(swiper) {
                    // console.log(this.realIndex);
                    $('.show-click-box .s' + (this.realIndex + 1)).parent().addClass('select').siblings().removeClass("select");
                },
            }
        });
    },
    // 图片资源预加载
    preloading: function() {
        //图片预加载
        var queue = new createjs.LoadQueue();
        queue.on("progress", function(e) {
            //加载中的进度
            if (e.progress * 100 >= 10) {
                // console.log(parseInt(e.progress * 100) + '%');
                $(".process-track .process-inner").width(parseInt(e.progress * 100) + '%');
                $(".process-percent #percent").html(parseInt(e.progress * 100));
            };
            // var numbers = e.progress * 100;
        }, this);
        queue.on("complete", function(e) {
            //加载完成执行操作
            $('.index').addClass('index-show');
            this.indexSwiper();
            if (go_page == undefined) {
                // pv暂时未提供，先隐藏
                // this.defaultVideo();
            }
            setTimeout(function() {
                $('#preloading').remove();
            }, 300)
        }, this);
        queue.loadManifest([
            //加载图片，不包括后台的
            '/official/images/pc/header_bg-c19b14b8ad.png',
            '/official/images/pc/code_img-00688d14e6.png',
            '/official/images/pc/apple_btn-20aa4117d1.png',
            '/official/images/pc/and_btn-6d4d59954d.png',
            '/official/images/pc/banner_bg-25db3b38c2.png',
            '/official/images/pc/music_btn-9284c56157.png',
            '/official/images/pc/index_bg-eb001b63ee.png',
            '/official/images/pc/video_play_btn-f98dbe5fbb.png',
            '/official/images/pc/video_btn1-5d98f907c7.png',
            '/official/images/pc/video_btn2-a007da6d18.png',
            '/official/images/pc/btn_line_bg-a1d9491161.png',
        ]);
    },
    defaultVideo: function() {
        var src = 'https://download4.qcplay.com/file/video_30m.mp4';
        $('.play-btn').fadeIn().attr('data', src);
        $('.video-wrap, .popup-video').fadeIn().find('iframe').attr('src', $('.play-btn').attr('data')).removeClass('ani-btn');

        $('.play-btn').on('click', function(event) {
            $('.video-wrap, .popup-video').fadeIn().find('iframe').attr('src', $('.play-btn').attr('data')).removeClass('ani-btn');
        });
        $('.popup').on('click', '.close-btn', function(event) {
            $('.video-wrap, .popup-video').find('iframe').attr('src', '');
        });
    },
    // 首页接口请求
    indexAjax: function() {
        // var index = layer.load(2, { shade: [0.1, '#fff'] });
        var _this = this;
        $.ajax({
            url: api + "/cherry/taiwan/index",
            // data: {
            //     appId: 58
            // },
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    var zonghe = json.data.articleList.zonghe_news; //综合
                    var activity = json.data.articleList.activity_news; //活动
                    var gongyi = json.data.articleList.gongyi; //公益
                    var zuixin = json.data.articleList.zuixin; //最新
                    var mengxin = json.data.articleList.mengxin; //萌新
                    var jinjie = json.data.articleList.jinjie; //进阶
                    var banner = json.data.articleList.banner; //新闻轮播
                    var music = json.data.articleList.music; //音乐
                    var video = json.data.articleList.video; //视频

                    // 下载地址
                    var ios_url = 'https://apps.apple.com/app/id6462015934';
                    var google_url = 'https://play.google.com/store/apps/details?id=com.snell.sakura.ggp.tw';
                    // 关注我们地址
                    var fb_url = 'https://www.facebook.com/ccsakuragame';
                    var fb_group_url = 'https://www.facebook.com/groups/1151513195758075';
                    var ins_url = 'https://www.instagram.com/sakura.memorykey';
                    // 充值平台地址
                    var pay_url = 'https://pay.snell.com.tw/';

                    // 下载按钮
                    $('.app-btn').attr('href', ios_url);
                    $('.google-btn').attr('href', google_url);
                    // 关注我们按钮
                    $('.facebook-btn , .fb-gz-btn').attr('href', fb_url);
                    $('.facebook-group-btn , .fb-group-gz-btn').attr('href', fb_group_url);
                    $('.instagram-btn , .ins-gz-btn').attr('href', ins_url);
                    // 充值平台按钮
                    $('.pay-btn , .top-up-btn').attr('href', pay_url);

                    // 【活动资讯】最新
                    $.each(zuixin, function(index, values) {
                        var zuixinHtml = '<li>' +
                            '<a href="news-details.html?id=' + values.id + '">' +
                            '<i></i>' +
                            '<h2>' + values.article_title + '</h2>' +
                            '<span>' + values.created_at + '</span>' +
                            '<em></em>' +
                            '</a>' +
                            '</li>';
                        $('.zuixin').append(zuixinHtml);
                    });

                    // 【活动资讯】公告
                    $.each(gongyi, function(index, values) {
                        var gonggaoHtml = '<li>' +
                            '<a href="news-details.html?id=' + values.id + '">' +
                            '<i></i>' +
                            '<h2>' + values.article_title + '</h2>' +
                            '<span>' + values.created_at + '</span>' +
                            '<em></em>' +
                            '</a>' +
                            '</li>';
                        $('.gonggao').append(gonggaoHtml);
                    });

                    // 【活动资讯】活动
                    $.each(activity, function(index, values) {
                        var activityHtml = '<li>' +
                            '<a href="news-details.html?id=' + values.id + '">' +
                            '<i></i>' +
                            '<h2>' + values.article_title + '</h2>' +
                            '<span>' + values.created_at + '</span>' +
                            '<em></em>' +
                            '</a>' +
                            '</li>';
                        $('.activity').append(activityHtml);
                    });
                    _this.showSwiper(); //套装展示轮播
                    _this.Textlength(25, 20);
                } else {
                    layer.msg("服务器请求失败", { time: 2000 });
                }
            },
            error: function() {
                layer.msg("服务器请求失败", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 全屏滚动判断
    scroll: function() {
        $(window).scroll(function() {
            var scrollTop = $(this).scrollTop();
            var scrollHeight = $(document).height();
            var windowHeight = $(this).height();
            $(document).scrollTop() > 400 ? $('.return-top').fadeIn() : $('.return-top').fadeOut();
        });

        $('.return-top').click(function(event) {
            $(document).scrollTop(0);
        });
    },
    // 首页轮播
    indexSwiper: function() {
        var mySwiper = new Swiper('.swiper-container', {
            pagination: '.swiper-pagination',
            direction: 'vertical',
            slidesPerView: 'auto',
            paginationClickable: true,
            spaceBetween: 0,
            mousewheel: true,
            resistanceRatio: 0,
            initialSlide: initialSlide,
            touchMoveStopPropagation: false,
            touchStartForcePreventDefault: true,
            on: {
                init: function() {
                    swiperAnimate(this);

                    function Ani(btn, page) {
                        $(btn).on('click', function() {
                            mySwiper.slideTo(page, 1000, true);
                        });
                    };

                    function navLink() {
                        for (var i = 6; i >= 0; i--) {
                            Ani('.nav-link-0' + i + ' .link-a', i);
                        };
                    }
                    navLink();

                    var aniLink01 = Ani('.arrow-b', '1');
                    var aniLink02 = Ani('.arrow-b2', '2');
                    var aniLink03 = Ani('.arrow-b4', '3');
                    var aniLink04 = Ani('.arrow-b5', '4');
                    var aniLink05 = Ani('.arrow-b6', '5');
                },
                slideChangeTransitionEnd: function() {
                    // mySwiper.activeIndex >= 7 ? $('.rocket').fadeIn() : $('.rocket').fadeOut();
                    // swiperAnimate(this);
                    // this.activeIndex !== 0 ? $('.header').addClass('header-shadow') : $('.header').removeClass('header-shadow');

                },
                transitionStart: function() {
                    swiperAnimate(this);
                    $('.nav-link').removeClass('on')
                    $('.nav-link-0' + this.activeIndex + '').addClass('on');
                },
            },
        });

        if (go_page !== undefined) {
            mySwiper.slideTo(go_page - 1, 1000, true);
        }

        var newsSwiper = new Swiper('.swiper-container-news', {
            loop: true,
            paginationClickable: true,
            pagination: {
                el: '.swiper-pagination2',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            slidesPerView: 'auto',
            // autoplay: 4000,
        });
        //鼠标覆盖停止自动切换
        // newsSwiper.container[0].onmouseover = function() {
        //     newsSwiper.stopAutoplay();
        // };
        // //鼠标移出开始自动切换
        // newsSwiper.container[0].onmouseout = function() {
        //     newsSwiper.startAutoplay();
        // };

        // 公益活动
        var charitySwiper = new Swiper('.swiper-container-charity', {
            loop: true,
            paginationClickable: true,
            pagination: {
                el: '.swiper-pagination3',
                clickable: true,
                renderBullet: function(index, className) {
                    switch (index) {
                        case 0:
                            text = '壹';
                            break;
                        case 1:
                            text = '贰';
                            break;
                        case 2:
                            text = '叁';
                            break;
                        case 3:
                            text = '肆';
                            break;
                    }
                    return '<span class="' + className + '">' + text + '</span>';
                },
            },
            noSwiping: true,
            // autoplay: true,
            autoplayDisableOnInteraction: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            observer: true,
            observerParents: true,
            speed: 500,
            onInit: function(swiper) {
                swiperAnimate(this);
                swiper.stopAutoplay();
            },
        });

        // 游戏特色
        function featureSwiper() {
            var charitySwiper = new Swiper('.swiper-container-feature', {
                loop: true,
                paginationClickable: true,
                pagination: {
                    el: '.swiper-pagination4',
                    clickable: true,
                },
                noSwiping: true,
                // autoplay: true,
                autoplayDisableOnInteraction: false,
                navigation: {
                    nextEl: '.swiper-button-next3',
                    prevEl: '.swiper-button-prev3',
                },
                initialSlide: 5,
                speed: 500,
                on: {
                    slideNextTransitionStart: function() {
                        $('.xk-wrap .hand').removeClass('h-next').addClass('h-prev')
                    },
                    slidePrevTransitionStart: function() {
                        $('.xk-wrap .hand').removeClass('h-prev').addClass('h-next')
                    }
                },
            });
            $('.xk-wrap .hand').on('animationend', function() {
                $(this).removeClass('h-next');
                $(this).removeClass('h-prev');
            })
        }
        featureSwiper();

        $('body').on('click', '.rocket', function(event) {
            var _this = $(this);
            _this.addClass('rocket-ani')
            mySwiper.slideTo(0, 1000, true);
            setTimeout(function() { _this.fadeOut() }, 1000);
            setTimeout(function() { _this.removeClass('rocket-ani') }, 1500);
        });
    },
    // 视频轮播
    videoSwiper: function() {
        function videoSwiper() {
            videoSwiper = new Swiper('.swiper-container-video', {
                loop: true,
                direction: 'vertical',
                // spaceBetween: 5.66,
                loopedSlides: 'auto',
                slidesPerView: 'auto',
                observer: true, //当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
                speed: 300,
                // nextButton: '.swiper-button-next2',
                // prevButton: '.swiper-button-prev2',
                onInit: function(swiper) {
                    swiper.slideTo(pageIndex === 1 ? imgIndex : videoIndex, 0, true);

                    // 当轮播点击的时候做的操作
                    // $(".img-box img").on('click', function(event) {
                    //     var _this = $(this);
                    //     var dataSrc = _this.attr('src');
                    //     // 替换上半部分显示的图的路径
                    //     $('.art img').attr("src", dataSrc);
                    // })

                    // 开放视频
                    // $(".videos-box img").on('click', function(event) {
                    //     var data = $(this).parent().parent().attr('data');
                    //     var _this = $(this);
                    //     var dataSrc = _this.attr('src');
                    //     // 替换上半部分显示的图的路径以及播放路径
                    //     $('.videos img').attr("src", dataSrc);
                    //     $('.videos a').attr("data", data);
                    // })

                    // 暂未开放视频
                    // $(".videos-box img").on('click', function(event) {
                    //     layer.msg("Coming soon. Please stay tuned!", { time: 2000 });
                    // })
                },
                onSlideChangeEnd: function(swiper) {
                    swiperAnimate(swiper);
                },
                onTouchEnd: function(swiper) {
                    swiperAnimate(swiper);
                },

            });
        };
        videoSwiper();
    },
    wookmarkBind: function() {
        var articleList = [
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/big/1.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/2.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/3.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/4.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/5.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/big/3.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/6.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/7.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/big/4.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/8.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/9.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/10.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/11.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/12.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/big/7.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/13.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/big/8.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/big/9.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/14.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/15.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/big/10.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/16.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/17.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/18.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/19.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/20.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/21.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/22.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/23.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/24.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/25.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/26.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/27.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/28.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/29.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/30.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/31.jpg' },
            { 'thumbnail': 'https://download4.qcplay.com/sakura/gallery/small/32.jpg' },
        ];
        $.each(articleList, function(index, values) {
            var dtHtml = '<li class="grid-item">' +
                '<img src=' + values.thumbnail + '>' +
                '</li>';
            $('#tiles').append(dtHtml);
        });

        $('#tiles').imagesLoaded(function() {
            var options = {
                itemWidth: 200,
                autoResize: true,
                container: $('#tiles'),
                offset: 15,
                outerOffset: 0,
                flexibleWidth: 285
            };
            var handler = $('#tiles li');
            handler.wookmark(options);
        });

        setTimeout(() => {
            $('.htmleaf-box').addClass('htmleaf-box-show');
        }, 1000);
    },
    // 游戏咨询&游戏攻略信息切换
    jqslideTxtBox: function() {
        jQuery(".slideTxtBox").slide({ effect: "left", trigger: "click", easing: "swing" });
        jQuery(".slideTxtBox-02").slide({ effect: "left", trigger: "click", easing: "swing" });
        jQuery(".gallery-list .main").slide({ effect: "left", trigger: "click", easing: "swing" });
    },
    // 视听回廊按钮事件
    galleryBind: function() {
        // 三个页面切换 初始化
        let page_id = this.getQueryVariable('page_id');
        $('.gallery-list .li-0' + page_id + '').addClass('on').siblings().removeClass('on');
        var btn = $('.gallery-list .li-0' + page_id + '');
        setTimeout(function() {
            btn.click();
        }, 300);

        // 视听回廊切换
        $('.gallery-list .hd li').click(function() {
            Media.pause();
            $('.record-player , .h-music-box').addClass('ani-pause'); //添加暂时播放样式
            $('.record-rocker').addClass('rocker-pause'); //添加暂时播放样式
            $('.music-box .play-box .play-btn').addClass('ani-pause'); //添加暂时播放样式
        });

        var Media = document.getElementById('media-play');
        // 媒体视频播放
        $('.video-btn').on('click', function(event) {
            var _this = $(this);
            $('.thumbnail-img').fadeOut(); //视频展示图
            $('.video-show-box video').fadeIn(); //视频
            $('.video-btn-box').fadeOut(); //视频按钮
            Media.play(); //播放
        });

        // 视频预览
        $('.swiper-container-video .videos-box').on('click', 'a', function() {
            var _this = $(this).find('img');
            var img_src = _this.attr('src');
            var video_src = _this.attr('data');
            var video_title = _this.attr('title');

            $(this).parent().addClass("on");
            $(this).parent().siblings().removeClass("on");
            // $('.thumbnail-img').fadeIn();
            // $('.video-btn-box').fadeIn();
            $('.video-show-box video').show();

            $('.thumbnail-img').find('img').attr('src', img_src); //图片填充
            $('.video-show-box').find('video').attr('src', video_src); //视频填充
            $('#videoName').html(video_title); //视频标题填充
            Media.play(); //播放
            $('.thumbnail-img').fadeOut();
            $('.video-btn-box').fadeOut();
        })

        // 歌曲选择按钮
        $('.select-btn').click(function(event) {
            $(".music-select-wrap").fadeIn();
        });

        // 点击背景关闭弹窗
        $('.music-select-wrap').click(function(event) {
            if ($(event.target)[0] == $(this)[0]) {
                $(this).fadeOut();
            }
        });
    },
    // 视听回廊接口
    galleryAjax: function() {
        var _this = this;
        music.pause();
        $.ajax({
            url: api + "/cherry/taiwan/index",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    var video = json.data.articleList.video; //视频

                    // 【游戏视频】
                    if (video.length !== 0) {
                        $(".video-show-box .thumbnail-img img").attr("src", video[0].thumbnail);
                        $(".video-show-box video").attr("src", video[0].video_link);
                        $(".content-box #videoName").html(video[0].article_title);
                    }

                    $('.content-box #videoName').each(function() {
                        if ($(this).text().length > 21) {
                            var str = $(this).text();
                            $(this).text(str.substr(0, 21) + '...');
                        }
                    });

                    $.each(video, function(index, values) {
                        var videoHtml = `<div class="swiper-slide videos-box">
                                <a href="javascript:;">
                                    <img src="${values.thumbnail}" data="${values.video_link}" title="${values.article_title}" alt="">
                                    <i></i>
                                    <b class="ani-btn"></b>
                                </a>
                            </div>`;
                        $('.swiper-container-video .slideVideoBox').append(videoHtml);
                    })
                    $('.swiper-container-video .slideVideoBox').children(':eq(0)').addClass('on');

                    _this.galleryBind();
                } else {
                    layer.msg("服务器请求失败", { time: 2000 });
                }
            },
            error: function() {
                layer.msg("服务器请求失败", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 内容字体长度超过40，标题字体长度超过25
    Textlength: function(x1, x2) {
        $('.slideTxtBox .bd p').each(function() {
            // console.log($(this).text().length);
            if ($(this).text().length > x1) {
                var str = $(this).text();
                $(this).text(str.substr(0, x1) + '...');
            }
        });
        $('.slideTxtBox .bd h2').each(function() {
            // console.log($(this).text().length);
            if ($(this).text().length > x2) {
                var str = $(this).text();
                $(this).text(str.substr(0, x2) + '...');
            }
        });
    },
    // 获取当前hash的具体参数
    getQueryVariable: function(variable) {
        var query = window.location.href.split('?')[1];
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    },
    // 视频和图片预览
    videoAndImg: function() {
        // 关闭视频 & 其他弹窗
        $('.popup').on('click', '.close-btn', function(event) {
            $('.video-wrap, .popup-video').fadeOut();
            $('.popup').fadeOut();
        });

        // 点击视频浏览
        $('.cartoon-ul').on('click', '.li-sp', function(event) {
            var _this = $(this);
            $('.video-wrap, .popup-video').fadeIn().find('iframe').attr('src', _this.find('img').attr('data')).removeClass('ani-btn');
        });

        $('.cartoon-ul').on('click', '.li-03', function(event) {
            $('.video-wrap, .popup-video').fadeIn();
        });
    },
    // 首页轮播
    s: function() {
        var newsSwiper = new Swiper('.swiper-container-news', {
            loop: true,
            paginationClickable: true,
            pagination: '.swiper-pagination2',
            slidesPerView: 'auto',
            autoplay: 4000,
        });
        //鼠标覆盖停止自动切换
        newsSwiper.container[0].onmouseover = function() {
            newsSwiper.stopAutoplay();
        };
        //鼠标移出开始自动切换
        newsSwiper.container[0].onmouseout = function() {
            newsSwiper.startAutoplay();
        };

        // 公益活动
        var charitySwiper = new Swiper('.swiper-container-charity', {
            loop: true,
            paginationClickable: true,
            pagination: '.swiper-pagination3',
            noSwiping: true,
            autoplay: 2000,
            autoplayDisableOnInteraction: false,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            effect: 'coverflow',
            centeredSlides: true,
            slidesPerView: 'auto',
            loopedSlides: '4',
            observer: true,
            observerParents: true,
            initialSlide: 3,
            speed: 700,
            coverflow: {
                rotate: -30, // 旋转的角度
                stretch: 5, // 拉伸   图片间左右的间距和密集度
                depth: 30, // 深度   切换图片间上下的间距和密集度
                modifier: 10, // 修正值 该值越大前面的效果越明显
                // slideShadows : false// 页面阴影效果
            },
            onInit: function(swiper) {
                swiperAnimate(swiper);
                swiper.stopAutoplay();
            },
        });
    },
    // 新闻最新接口
    newsZxList: function() {
        var cate_id, page, current, index;
        cate_id = obj.getQueryVariable('cate_id');
        page = obj.getQueryVariable('page');
        current = window.location.href.split("?")[0];
        index = layer.load(2, { shade: [0.1, '#fff'] });
        $('.news .all-list .li-tab-0' + cate_id + '').addClass('on').siblings('li').removeClass('on');
        $.ajax({
            // url: api + "/article",
            url: "https://api.qingcigame.com/web/article",
            type: "GET",
            data: {
                game_id: 74,
                cate_id: cate_id,
                page: page,
            },
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    var current_page = json.data.articleList.current_page; // 当前页数
                    var last_page = json.data.articleList.last_page; // 总共页数
                    var prev_page_url = json.data.articleList.prev_page_url; // 前一页
                    var next_page_url = json.data.articleList.next_page_url; // 后一页
                    var _data = json.data.articleList.data;
                    if (_data.length == 0) {
                        $('.main').addClass('list-null');
                    } else {
                        $('.main').removeClass('list-null');
                        // 渲染列表
                        $.each(_data, function(index, values) {
                            var dtHtml = `<li>
                            <a title="${values.article_title}" href="news-details-mobile.html?id=${values.id}">
                                <img src=${values.index_pc_img}>
                                <div class="info-box">
                                <h2>${values.article_title}</h2>
                                <span>${values.created_at}</span>
                                <b></b>
                                </div>
                            </a>
                        </li>`;
                            $('.new-list ul').append(dtHtml);
                        });
                        $('.new-list').addClass('new-list-show');
                        $('.current_page').html(current_page); // 当前页数
                        $('.last_page').html(last_page); // 总共页数
                        if (prev_page_url === null) { // 上一页
                            $('.front-link').hide(); // 没有第一页
                        } else {
                            $('.front-link').attr('href', current + '?cate_id=' + cate_id + '&page=' + prev_page_url.split("?page=")[1])
                        }
                        if (next_page_url === null) { // 下一页
                            $('.after-link').hide(); // 没有最后一页
                        } else {
                            $('.after-link').attr('href', current + '?cate_id=' + cate_id + '&page=' + next_page_url.split("?page=")[1])
                        }
                        $('.new-list').addClass('new-list-show');
                    }
                } else {
                    layer.msg("服务器请求失败", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("服务器请求失败", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    analysisParams: function(url) {
        const params = {};
        const paramsString = url.split('?')[1];
        if (!paramsString) {
            return params;
        }
        const str = paramsString.split('&');
        for (let idx = 0; idx < str.length; idx++) {
            const values = str[idx].split('=');
            params[values[0]] = values[1];
        }
        return params;
    },
    // 游戏攻略接口
    newsIntrList: function() {
        const params = obj.analysisParams(window.location.href);
        var cate_id, page, current, index;
        cate_id = params.cate_id;
        page = params.page;

        current = window.location.href.split("?")[0];
        index = layer.load(2, { shade: [0.1, '#fff'] });
        $('.news .tab-title .a-tab-0' + cate_id + '').addClass('on').siblings('a').removeClass('on');
        var _this = $(this);
        $.ajax({
            // url: api + "/new/list",
            url: "https://api.qingcigame.com/web/article",
            type: "GET",
            data: {
                // cate_id 最新-综合-活动
                // page    1...
                game_id: 69,
                cate_id: cate_id,
                page: page,
            },
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    // console.log(json.data);
                    var current_page = json.data.articleList.current_page; // 当前页数
                    var last_page = json.data.articleList.last_page; // 总共页数
                    var prev_page_url = json.data.articleList.prev_page_url; // 前一页
                    var next_page_url = json.data.articleList.next_page_url; // 后一页
                    var _data = json.data.articleList.data;
                    if (_data.length == 0) {
                        $('.main').addClass('list-null');
                    } else {
                        $('.main').removeClass('list-null');
                        // 渲染列表
                        $.each(_data, function(index, values) {
                            var dtHtml = '<li>' +
                                '<a title="' + values.article_title + '" href="introduction-details.html?id=' + values.id + '">' +
                                '<p class="lside">' +
                                '<span class="time">' + values.created_at + '</span>' +
                                '<span class="title" style="display: block;">' + values.article_title + '</span>' +
                                '</p>' +
                                '</a>' +
                                '</li>';
                            $('.new-list ul').append(dtHtml);
                        });
                        $('.new-list').addClass('new-list-show');
                        $('.current_page').html(current_page); // 当前页数
                        $('.last_page').html(last_page); // 总共页数
                        if (prev_page_url === null) { // 上一页
                            $('.front-link').hide(); // 没有第一页
                        } else {
                            $('.front-link').attr('href', current + '?cate_id=' + cate_id + '&page=' + prev_page_url.split("?page=")[1])
                        }
                        if (next_page_url === null) { // 下一页
                            $('.after-link').hide(); // 没有最后一页
                        } else {
                            $('.after-link').attr('href', current + '?cate_id=' + cate_id + '&page=' + next_page_url.split("?page=")[1])
                        }
                    }

                    // 新闻标题限制字数
                    // _this.maxLength(".swiper-container-news .slideTxtBox-box p", 50);

                } else {
                    $('.pager').css('opacity', '0');
                    layer.msg("服务器请求失败", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("服务器请求失败", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 最新、综合、活动跳转
    newsTabPage: function() {
        $('.news .all-list').on('click', 'li', function(event) {
            $('.all-list li').removeClass('on');
            $(this).addClass('on');
            // $(this).addClass('on').siblings('li').removeClass('on');
            var cate_id = $(this).attr('cate-id');
            window.location.href = window.location.href.split("?")[0] + '?cate_id=' + cate_id + '&page=1'
        });
    },
    // 新闻详情
    newsInfo: function() {
        var id = obj.getQueryVariable('id');
        var index = layer.load(2, { shade: [0.1, '#fff'] });
        $.ajax({
            // url: api + "/article/" + id + "",
            url: "https://api.qingcigame.com/web/article/" + id + "",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                $('.news-det .main').addClass('main-show');
                if (json.code == 200) {
                    var newsTitle = json.data.list.article_title; // 标题
                    var detailsInfo = json.data.list.article_content; // 内容
                    var origin = json.data.list.article_excerpt; // 作者
                    var created_at = json.data.list.created_at; // 时间
                    $('.news .news-title h3, .current-title').html(newsTitle);
                    $('.news .origin').html(origin);
                    $('.news .created_at').html(created_at);
                    $('.details-info').append(detailsInfo);

                    // 翻页功能
                    obj.pageDown(json.data.last, json.data.next, 'id');

                    $('title').html(newsTitle);
                    $('meta[name="keywords"]').attr('content', json.data.list.article_keywords);
                    $('meta[name="description"]').attr('content', json.data.list.article_excerpt);
                } else {
                    layer.msg("服务器请求失败", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("服务器请求失败", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 翻页功能
    pageDown: function(lastPage, nextPage, cite) {
        var href = window.location.href.split("?")[0] + '?' + cite + '='
        if (lastPage !== '') $('.prev-page').addClass('show').attr('href', href + lastPage.id).attr('title', lastPage.article_title);
        if (nextPage !== '') $('.next-page').addClass('show').attr('href', href + nextPage.id).attr('title', nextPage.article_title);
    },
    // 获取同名动画视频
    sameNameAjax: function() {
        var id = obj.getQueryVariable('id');
        var index = layer.load(2, { shade: [0.1, '#fff'] });
        $.ajax({
            url: api + "/article?cate_id=" + id + "&limit=0",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    // 渲染视频数量
                    $.each(json.data.articleList1, function(index, values) {
                        var dtHtml = '<li data="' + values.video_link + '" ><a href="javascript:;">' + Number(index + 1) + '</a></li>';
                        $('.outline-01 .select-ul').append(dtHtml);
                    });

                    $.each(json.data.articleList2, function(index, values) {
                        var dtHtml = '<li data="' + values.video_link + '" ><a href="javascript:;">' + Number(index + 1) + '</a></li>';
                        $('.outline-02 .select-ul').append(dtHtml);
                    });

                    var firstLink = json.data.articleList2[0].video_link;
                    $('.left-video iframe').attr('src', firstLink);

                    var number = $('.outline').length;
                    $('.right-select h3 span').html('1/' + number + '');

                    for (var i = number; i >= 1; i--) {
                        var videoUrl = $('.outline-0' + i + ' .select-ul').children("li").eq(0).addClass('on').attr('data');
                        $('.outline-0' + i + ' .left-video iframe').attr('src', videoUrl);
                        for (var n = 0; n <= $('.outline-0' + i + ' .select-ul li').length; n++) {
                            $('.outline-0' + i + ' .right-select span').html('1/' + n + '');
                        };
                    };


                } else {
                    layer.msg("服务器请求失败", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("服务器请求失败", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 视频切换
    videoSwitch: function() {
        $('.select-ul').on('click', 'li', function(event) {
            var playSrc = $(this).attr('data');
            $(this).addClass('on').siblings().removeClass('on');
            $(this).parents('.right-select').siblings('.left-video').find('iframe').attr('src', playSrc);
        });
    },
    // 选择不同频道
    channel: function() {
        $('.label').on('click', 'h4', function(event) {
            $(this).addClass('on').siblings().removeClass('on').parent('.label').siblings('.int-wrap').children('.int-bg').eq($(this).index()).addClass('show').siblings().removeClass('show');
            $(this).parents('.introduce').siblings('.animation-content').children('.outline').eq($(this).index()).addClass('show').siblings().removeClass('show');
            tabVideo();
        });

        // 关闭所有视频
        function tabVideo() {
            var auVideo = document.getElementsByTagName("video");
            for (var i = 0; i < auVideo.length; i++) {
                auVideo[i].pause();
            }
        }
    },
    // 获取视频中心视频
    videoCenterAjax: function() {
        var id = obj.getQueryVariable('id');
        var limit = 5; // 精彩视频个数5个
        var index = layer.load(2, { shade: [0.1, '#fff'] });
        // 精彩视频5个
        $.ajax({
            url: api + "/article?cate_id=" + id + "&limit=" + limit + "",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    // 渲染视频数量
                    $.each(json.data.articleList.data, function(index, values) {
                        var dtHtml = '<li data="' + values.video_link + '">' +
                            '<div class="pic"><span><img src="' + values.thumbnail + '"></span></div>' +
                            '<div class="name">' +
                            '<h4>' + values.article_title + '</h4>' +
                            '<p>' + values.article_excerpt + '</p>' +
                            '</div>' +
                            '</li>';
                        $('.video-content .select-ul').append(dtHtml);
                    });
                    // // 第一个视频默认选中，并播放
                    var oneLi = $('.video-center .select-ul').children("li").eq(0);
                    oneLi.addClass('on').attr('data');
                    $('.left-video iframe').attr('src', oneLi.attr('data'));
                } else {
                    layer.msg("服务器请求失败", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("服务器请求失败", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })

        // 全部视频
        $.ajax({
            url: api + "/article?cate_id=" + id + "&limit=0",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    // 渲染视频数量
                    $.each(json.data.articleList, function(index, values) {
                        var dtHtml = '<li>' +
                            '<a href="video-details.html?id=' + values.id + '">' +
                            '<span>' +
                            '<img src="' + values.thumbnail + '">' +
                            '</span>' +
                            '</a>' +
                            '<p>' + values.article_title + '</p>' +
                            '</li>';
                        $('.more-videos ul').append(dtHtml);
                    });
                } else {
                    layer.msg("服务器请求失败", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("服务器请求失败", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 获取详细视频
    videoDetailsAjax: function() {
        var id = obj.getQueryVariable('id');
        var index = layer.load(2, { shade: [0.1, '#fff'] });
        $.ajax({
            url: api + "/article/" + id + "",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    var newsTitle = json.data.list.article_title; // 标题
                    var detailsInfo = json.data.list.article_content; // 内容
                    var origin = json.data.list.origin; // 作者
                    var created_at = json.data.list.created_at; // 时间
                    var video_link = json.data.list.video_link; // 视频
                    $('.video-center .news-title h3, .current-title').html(newsTitle);
                    $('.video-center .details-info').append(detailsInfo);
                    $('.video-center .origin').html(origin);
                    $('.video-center .created_at').html(created_at);
                    $('.video-info iframe').attr('src', video_link);
                    // 翻页功能
                    obj.pageDown(json.data.last, json.data.next, 'id');

                    $('title').html(newsTitle);
                    $('meta[name="keywords"]').attr('content', json.data.list.article_keywords);
                    $('meta[name="description"]').attr('content', json.data.list.article_excerpt);
                } else {
                    layer.msg("服务器请求失败", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("服务器请求失败", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 获取漫画续集信息
    comicSequelAjax: function() {
        var id = obj.getQueryVariable('id');
        var index = layer.load(2, { shade: [0.1, '#fff'] });
        $.ajax({
            url: api + "/article?cate_id=" + id + "&limit=0",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    // 渲染漫画列表数量

                    $.each(json.data.articleList, function(index, values) {
                        var dtHtml = '<li class="ani-btn"><a href="news-details.html?id=' + values.id + '">' + values.article_title + '</a></li>';
                        $('.cartoon-list ul').append(dtHtml);
                    });
                    $('.cartoon-list ul li:nth-child(1)').html();
                    var info = $('.cartoon-list ul li:nth-child(1) a').html()
                    var href = $('.cartoon-list ul li:nth-child(1) a').attr('href');
                    $('.synopsis .start').attr('href', href).find('span').html(info);
                    // $('.cartoon-list span').html('全'+parseInt(json.data.articleList.length)+'話');

                } else {
                    layer.msg("服务器请求失败", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("服务器请求失败", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // wiki-贵重物品-筛选
    filtering: function() {
        rarity = ''; // 品阶
        deviation = ''; // 五围
        halo_effect = ''; // 光环技能
        mosaic_skill = ''; // 镶嵌技能
        column = ''; // 筛选参数1
        order = ''; // 筛选参数2
        page = ''; // 第几页
        url = api + '/goods'; // 需要请求的地址

        // 第一次进入时候请求一次
        function wikiDataAjax(url) {
            $('.loading-wrap').fadeIn();
            $.ajax({
                url: url,
                type: "GET",
                data: {
                    rarity: rarity,
                    deviation: deviation,
                    halo_effect: halo_effect,
                    mosaic_skill: mosaic_skill,
                    column: column,
                    order: order,
                    page: page
                },
                xhrFields: {
                    withCredentials: true
                },
                success: function(json) {
                    if (json.code == 200) {
                        var next_page_url = json.data.data.next_page_url; // 下一页的数据
                        // 渲染-所有贵重物品
                        // 橙色-1
                        // 蓝色-2
                        // 绿色-3
                        // 紫色-4
                        if (json.data.data.data.length !== 0) {
                            $.each(json.data.data.data, function(index, values) {
                                var addHtml = '<li class="li-color-0' + values.colour + '" titles="' + values.title + '"colour="' + values.colour + '" art="' + values.art + '"  culture="' + values.culture + '" describe="' + values.describe + '" deviation="' + values.deviation + '" faith="' + values.faith + '" get_type="' + values.get_type + '" halo_effect="' + values.halo_effect + '" id="' + values.id + '" mosaic_skill="' + values.mosaic_skill + '" popularity="' + values.popularity + '" quality="' + values.quality + '" rarity="' + values.rarity + '" rarity_order="' + values.rarity_order + '" technology="' + values.technology + '">' +
                                    '<div>' +
                                    '<img src="https://download4.qcplay.com/qcgame-admin/snailValuables/' + values.id + '.png">' +
                                    '</div>' +
                                    '<span>' + values.title + '</span>' +
                                    '</li>';
                                $('.goods-list-wrap ul').append(addHtml);
                            });
                            // 如果下页有参数则显示更多按钮
                            next_page_url !== null ? $('.more-wrap').fadeIn().attr('data', next_page_url) : $('.more-wrap').hide();
                            $('.tips-wrap, .loading-wrap').hide();
                        } else {
                            layer.msg("お探しの貴重品が見つかりません", { time: 2000 });
                            $('.tips-wrap').fadeIn();
                        }
                        $('.loading-wrap').hide();
                    } else {
                        $('.loading-wrap').fadeIn().html('服务器请求失败');
                    }

                },
                error: function() {
                    $('.loading-wrap').fadeIn().html('服务器请求失败');
                },
                complete: function() {
                    // layer.close(index);
                }
            })
        }
        wikiDataAjax(url);

        // 点击选择按钮
        $('.option-selection-box .selection-box').on('click', 'li', function(event) {
            page = 1; // 每次点击按钮的时候回到第一页
            var _this = $(this);
            _this.addClass('on').siblings().removeClass('on');
            getLiData(); // 获取条件数据
            $('.goods-list-wrap ul').find('li').remove(); // 先清除一次再渲染
            wikiDataAjax(url); // 选择条件数据后ajax请求            
        });


        // 点击更多查看下一页
        $('.more-wrap').click(function(event) {
            page = $(this).attr('data').split("?page=")[1];
            getLiData(); // 获取条件数据
            wikiDataAjax(url) // 选择条件数据后ajax请求
        });

        // 获取数据信息
        function getLiData() {
            rarity = $('.grade').find('.on').attr('data'); // 品阶
            deviation = $('.dimension').find('.on').attr('data'); // 五围
            halo_effect = $('.halo_effect').find('.on').attr('data'); // 光环技能
            mosaic_skill = $('.mosaic_skill').find('.on').attr('data'); // 镶嵌技能
            column = $('.grade_array').find('.on').attr('column'); // 筛选参数1
            order = $('.grade_array').find('.on').attr('order'); // 筛选参数2
        }

        // wiki-贵重物品-筛选-复位清除选项
        $('.reset-btn').click(function(event) {
            $('.goods-list-wrap ul').find('li').remove();
            $('.selection-box ul li:nth-child(1)').addClass('on').siblings().removeClass('on');
            rarity = ''; // 品阶
            deviation = ''; // 五围
            halo_effect = ''; // 光环技能
            mosaic_skill = ''; // 镶嵌技能
            column = ''; // 筛选参数1
            order = ''; // 筛选参数2
            page = ''; // 第几页
            url = api + "/goods"; // 需要请求的地址
            wikiDataAjax(url)
        });
    },
    // 信息切换-关闭弹窗清除数据
    wikiTabInfo: function() {
        $('.info-box .tit').on('click', 'h4', function(event) {
            $(this).addClass('on').siblings().removeClass('on').parent('.tit').siblings('.con').children('div').eq($(this).index()).addClass('show').siblings().removeClass('show');
        });
    },
    // 贵重物品弹窗
    wikiPopup: function() {
        // 开启弹窗
        $('.goods-list-wrap').on('click', 'li', function(event) {
            $('.wiki-popup').fadeIn();
            var title = $(this).attr('titles'); // 标题
            var colour = $(this).attr('colour'); // 颜色
            var art = $(this).attr('art'); // 艺术
            var culture = $(this).attr('culture'); // 文化
            var describe = $(this).attr('describe'); // 简介
            var deviation = $(this).attr('deviation'); // 偏向
            var faith = $(this).attr('faith'); // 信仰
            var get_type = $(this).attr('get_type'); // 获取途径  
            var halo_effect = $(this).attr('halo_effect'); // 光环技能
            var id = $(this).attr('id'); // 图片id
            var mosaic_skill = $(this).attr('mosaic_skill'); // 镶嵌技能
            var popularity = $(this).attr('popularity'); // 人气
            var quality = $(this).attr('quality');
            var rarity = $(this).attr('rarity');
            var rarity_order = $(this).attr('rarity_order');
            var technology = $(this).attr('technology'); // 科技

            if (rarity == '无') rarity = '' // 无不输出

            $('.wiki-wrap h1').html(title); // 标题
            $('.wiki-wrap .span-pj').html(quality + rarity); // 颜色
            $('.wiki-wrap .span-px').html(deviation); // 偏向

            $('.wiki-wrap .pic-img img').attr('src', 'https://download4.qcplay.com/qcgame-admin/snailValuables/' + id + '.png')
            $('.wiki-wrap .pic-img').addClass('pic-img-0' + colour + ''); // 框框颜色

            if (halo_effect == '') halo_effect = '暂无数据'
            if (mosaic_skill == '') mosaic_skill = '暂无数据'
            if (get_type == '') get_type = '暂无数据'
            if (describe == '') describe = '暂无数据'
            $('.wiki-wrap .p-01').html(halo_effect); // 光环技能
            $('.wiki-wrap .p-02').html(mosaic_skill); // 镶嵌技能
            $('.wiki-wrap .p-03').html(get_type); // 镶嵌技能
            $('.wiki-wrap .p-04').html(describe); // 简介

            $('.wiki-wrap .data-ul .li-01').html(popularity); // 人气
            $('.wiki-wrap .data-ul .li-02').html(art); // 艺术
            $('.wiki-wrap .data-ul .li-03').html(faith); // 信仰
            $('.wiki-wrap .data-ul .li-04').html(culture); // 文化
            $('.wiki-wrap .data-ul .li-05').html(technology); // 科技

            if (colour == 1) colour = '#f9ab61'
            if (colour == 2) colour = '#9ECBD2'
            if (colour == 3) colour = '#b4cf7b'
            if (colour == 4) colour = '#DCA8C7'
            myChart(popularity, art, faith, culture, technology, colour);
        });

        // 关闭弹窗
        $('.wiki-popup').on('click', '.close-btn', function(event) {
            $('.wiki-popup').fadeOut();
            setTimeout(function() {
                for (var i = 4; i >= 0; i--) $('.wiki-wrap .pic-img').removeClass('pic-img-0' + i + ''); // 框框颜色
                $('.wiki-wrap .pic-img img').attr('src', '');
                $('.wiki-wrap h1, .wiki-wrap h2 span').html(''); // 清空标题/颜色
                $('.wiki-wrap .con p').html(''); // 清空光环技能/镶嵌技能/镶嵌技能/简介
                $('.wiki-wrap .data-ul li').html(''); // 清空/人气/艺术/信仰/文化/科技
                $('.wiki-wrap .tit .h4-01').addClass('on').siblings().removeClass('on'); // tab恢复
                $('.wiki-wrap .con div:nth-child(1)').addClass('show').siblings().removeClass('show'); // tab恢复
            }, 500)
        });

        function myChart(popularity, art, faith, culture, technology, colour) {
            //指定图表的配置项和数据
            option = {
                tooltip: {},
                radar: {
                    center: ['50%', '60%'],
                    startAngle: 90,
                    name: {
                        formatter: '{value}',
                        textStyle: {
                            color: '#937f80',
                            fontSize: '12',
                            lineHeight: 14,
                            padding: [0, 0],
                        },
                        formatter: function(data, indicator) {
                            var data0 = data.substring(0, 2)
                            var data1 = data.substring(2)
                            if (data1 == maxData) indicator.nameTextStyle.color = colour
                            return data0 + '\n' + data1
                                // return val.split(":").join("\n");
                        },
                    },
                    // 指示器名称和指示器轴的距离。[ default: 5 ]
                    nameGap: 5,
                    // 雷达图圈数设置
                    splitNumber: 2,
                    // 设置雷达图中间射线的颜色
                    axisLine: {
                        lineStyle: {
                            width: 3,
                            color: '#ae988b',
                        },
                    },
                    splitArea: {
                        show: true,
                        areaStyle: {
                            color: '#EDDCC4', // 图表背景的颜色
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            width: 3,
                            color: '#ac998b', // 设置网格的颜色
                        },
                    },
                    indicator: [
                        { name: '人气' + popularity, max: 120 },
                        { name: '科技' + technology, max: 120 },
                        { name: '文化' + culture, max: 120 },
                        { name: '信仰' + faith, max: 120 },
                        { name: '艺术' + art, max: 120 },
                    ]
                },
                series: [{
                    type: 'radar',
                    itemStyle: { // 折线拐点标志的样式。
                        normal: { // 普通状态时的样式
                            lineStyle: {
                                width: 3,
                                color: colour,
                            },
                        },
                    },
                    data: [{
                        value: [popularity, technology, culture, faith, art],
                        itemStyle: { // 单个拐点标志的样式设置。
                            normal: {
                                color: 'rgba(60,135,213,.3)',
                                opacity: 0.2,
                                // 拐点的描边颜色。[ default: '#000' ]
                                borderWidth: 1,
                                // 拐点的描边宽度，默认不描边。[ default: 0 ]
                            }
                        },
                        areaStyle: {
                            normal: {
                                color: colour // 选择区域颜色
                            }
                        }

                    }, ],
                }]
            };
            var maxData = Math.max.apply(this, option.series[0].data[0].value)
                //获取dom容器
            var myChart = echarts.init(document.getElementById('chartmain'));
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
        }
    },
    // wiki-贵重物品-搜索
    wikiearch: function() {
        var _this = this;
        $('.search-btn').on('click', function(event) {
            var searchIpu = $('.search-input').val()
            if (searchIpu == '') {
                layer.msg("検索するIDをを入力してください", { time: 2000 });
                return
            }
            var index = layer.load(2, { shade: [0.1, '#fff'] });
            $.ajax({
                url: api + "/goods",
                type: "GET",
                data: {
                    title: searchIpu
                },
                xhrFields: {
                    withCredentials: true
                },
                success: function(json) {
                    if (json.code == 200) {
                        var next_page_url = json.data.data.next_page_url; // 下一页的数据
                        // 如果下页有参数则显示更多按钮
                        next_page_url !== null ? $('.more-wrap').fadeIn().attr('data', next_page_url) : $('.more-wrap').hide();

                        $('.search-input').val('') // 搜索完成后搜索栏目清空
                            // 清除信息
                        var gradeSet = resetSet('.grade');
                        var dimensionSet = resetSet('.dimension');
                        var halo_effectSet = resetSet('.halo_effect');
                        var mosaic_skillSet = resetSet('.mosaic_skill');
                        var grade_arraySet = resetSet('.grade_array');

                        function resetSet(li) {
                            $(li).find('li').removeClass('on');
                        }

                        $('.goods-list-wrap ul').find('li').remove();
                        if (json.data.data.data == '') {
                            layer.msg("お探しの貴重品が見つかりません", { time: 2000 });
                            $('.tips-wrap').fadeIn();
                        } else {
                            $.each(json.data.data.data, function(index, values) {
                                var addHtml = '<li class="li-color-0' + values.colour + '" titles="' + values.title + '"colour="' + values.colour + '" art="' + values.art + '" culture="' + values.culture + '" describe="' + values.describe + '" deviation="' + values.deviation + '" faith="' + values.faith + '" get_type="' + values.get_type + '" halo_effect="' + values.halo_effect + '" id="' + values.id + '" mosaic_skill="' + values.mosaic_skill + '" popularity="' + values.popularity + '" quality="' + values.quality + '" rarity="' + values.rarity + '" rarity_order="' + values.rarity_order + '" technology="' + values.technology + '">' +
                                    '<div>' +
                                    '<img src="https://download4.qcplay.com/qcgame-admin/snailValuables/' + values.id + '.png">' +
                                    '</div>' +
                                    '<span>' + values.title + '</span>' +
                                    '</li>';
                                $('.goods-list-wrap ul').append(addHtml);
                            });
                            $('.tips-wrap').hide();
                        }
                    }
                },
                error: function() {
                    layer.msg("服务器请求失败", { time: 2000 });
                },
                complete: function() {
                    layer.close(index);
                }
            })
        });
    },
    // 获取需要筛选的wiki参数
    getWikiData: function() {
        var index = layer.load(2, { shade: [0.1, '#fff'] });
        $.ajax({
            url: api + "/get_type",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    // 渲染-wiki-光环技能的参数
                    $.each(json.data.data.halo_effect, function(index, values) {
                        var addHtml = '<li data="' + values + '">' + values + '</li>';
                        $('.halo_effect').append(addHtml);
                    });

                    // 渲染-wiki-镶嵌技能的参数
                    $.each(json.data.data.mosaic_skill, function(index, values) {
                        var addHtml = '<li data="' + values + '">' + values + '</li>';
                        $('.mosaic_skill').append(addHtml);
                    });
                    $('.halo_effect, .mosaic_skill').find('li:nth-child(1)').addClass('on').attr('data', '');
                } else {
                    layer.msg("服务器请求失败", { time: 2000 });
                }

            },
            error: function() {
                layer.msg("服务器请求失败", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // wikiSwiper的轮播
    wikiSwiper: function() {
        var swiper = new Swiper('.swiper-container', {
            pagination: '.swiper-pagination',
            paginationClickable: true,
            spaceBetween: 30,
        });
    },
    wikiIndexAjax: function() {
        var index = layer.load(2, { shade: [0.1, '#fff'] });
        var _this = this;
        $.ajax({
            url: api + "/getdata",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            success: function(json) {
                if (json.code == 200) {
                    var ditu = json.data.articleList.ditu; // 地图探索
                    var hot = json.data.articleList.hot; // 热门
                    var xinshou = json.data.articleList.xinshou.data; // 新手
                    var xingtai = json.data.articleList.xingtai; // 形态
                    var hot = json.data.articleList.hot; // 热门
                    var banner = json.data.articleList.banner; // 轮播
                    var index = json.data.articleList.index; // 轮播

                    // // 渲染-地图探索
                    $.each(banner, function(index, values) {
                        var addHtml = '<div class="swiper-slide">' +
                            '<a href="' + values.url + '">' +
                            '<img src="' + values.mobile_background + '">' +
                            '</a>' +
                            '</div>';
                        $('.swiper-container .swiper-wrapper').append(addHtml);
                    });
                    _this.wikiSwiper();

                    // 渲染-地图探索
                    $.each(ditu, function(index, values) {
                        var addHtml = '<li><a href="news-details.html?id=' + values.id + '"><img src="' + values.thumbnail + '"><span>' + values.article_title + '</span></a></li>';
                        $('.map-exploration ul').prepend(addHtml);
                    });

                    $('.map-exploration li:nth-child(1)').find('span').html('高麗');
                    $('.map-exploration li:nth-child(2)').find('span').html('大和');
                    $('.map-exploration li:nth-child(3)').find('span').html('中華');
                    $('.map-exploration li:nth-child(4)').find('span').html('米国');
                    $('.map-exploration li:nth-child(5)').find('span').html('英伦');

                    // 渲染-热门
                    $.each(hot, function(index, values) {
                        var addHtml = '<li><a href="news-details.html?id=' + values.id + '">' + values.article_title + '</a></li>';
                        $('.hot-pages ul').append(addHtml);
                    });
                    // 新手指南
                    for (var i = $('.novice-guide li').length - 1; i >= 0; i--) {
                        $('.novice-guide .li-0' + i + ' a').attr('href', 'news-details.html?id=' + xinshou[i].id + '');
                    };

                    // 渲染-形态
                    $.each(xingtai, function(index, values) {
                        var addHtml = '<li><a href="news-details.html?id=' + values.id + '"><img src="' + values.thumbnail + '"><span>' + values.article_title + '</span></a></li>';
                        $('.form-intr ul').prepend(addHtml);
                    });

                    // 先固定写死
                    $('.form-intr li:nth-child(1)').find('span').html('ゾンビ');
                    $('.form-intr li:nth-child(2)').find('span').html('悪魔');
                    $('.form-intr li:nth-child(3)').find('span').html('天使');
                    $('.form-intr li:nth-child(4)').find('span').html('メカ');
                    $('.form-intr li:nth-child(5)').find('span').html('異形');
                    $('.form-intr li:nth-child(6)').find('span').html('ドラゴン');

                    // // 游戏攻略
                    $.each(index, function(index, values) {
                        var addHtml = '<li><a href="news-details.html?id=' + values.id + '"><span>' + values.cate_id + '</span><p>' + values.article_title + '</p></a></li>';
                        $('.game-intr ul').append(addHtml);
                    });

                } else {
                    layer.msg("服务器请求失败", { time: 2000 });
                }
            },
            error: function() {
                layer.msg("服务器请求失败", { time: 2000 });
            },
            complete: function() {
                layer.close(index);
            }
        })
    },
    // 字数限制
    maxLength: function(x, y) {
        $(x).each(function() {
            if ($(this).text().length > y) {
                var str = $(this).text();
                $(this).text(str.substr(0, y) + '...');
            }
        });
    },
    pageShow: function() {
        $('.page').addClass('pageShow');
    },
    // share: function() {
    //     $.ajax({
    //         url: api + "/wechat/jssdk",
    //         type: "POST",
    //         data: {
    //             uri: window.location.href,
    //         },
    //         success: function(json) {
    //             if (json.code == 200) {
    //                 var ua = navigator.userAgent.toLowerCase();
    //                 if (ua.match(/MicroMessenger/i) == "micromessenger") {
    //                     wx.config(json.data);
    //                     wx.ready(function() {
    //                         wx.updateAppMessageShareData({
    //                             title: '魔卡少女樱：回忆钥匙', // 分享标题
    //                             desc: '收集全世界的可爱', // 分享描述
    //                             link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    //                             imgUrl: 'https://download4.qcplay.com/qcgame-admin/dnX29EuzSenmU1Vt8cDJvCOGHofjdxkHtEs0Lzhm.png', // 分享图标
    //                             success: function() {}
    //                         })

    //                         wx.updateTimelineShareData({
    //                             title: '魔卡少女樱：回忆钥匙 - 收集全世界的可爱', // 分享标题
    //                             link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    //                             imgUrl: 'https://download4.qcplay.com/qcgame-admin/dnX29EuzSenmU1Vt8cDJvCOGHofjdxkHtEs0Lzhm.png', // 分享图标
    //                             success: function() {
    //                                 // 设置成功
    //                             }
    //                         })
    //                     });
    //                     return;
    //                 } else {
    //                     try {
    //                         setShareInfo({
    //                             title: '魔卡少女樱：回忆钥匙', // 分享标题
    //                             summary: '收集全世界的可爱', // 分享内容
    //                             pic: 'https://download4.qcplay.com/qcgame-admin/dnX29EuzSenmU1Vt8cDJvCOGHofjdxkHtEs0Lzhm.png', // 分享图片
    //                             url: window.location.href, // 分享链接
    //                             // 微信权限验证配置信息，若不在微信传播，可忽略
    //                             WXconfig: {
    //                                 swapTitleInWX: true, // 是否标题内容互换（仅朋友圈，因朋友圈内只显示标题）
    //                                 appId: json.data.appId, // 公众号的唯一标识
    //                                 timestamp: json.data.timestamp, // 生成签名的时间戳
    //                                 nonceStr: json.data.nonceStr, // 生成签名的随机串
    //                                 signature: json.data.signature // 签名
    //                             }
    //                         });
    //                     } catch (err) {}
    //                 }
    //             }
    //         }
    //     })
    // },
    share() {
        var share_icon =
            "https://download4.qcplay.com/qcgame-admin/dnX29EuzSenmU1Vt8cDJvCOGHofjdxkHtEs0Lzhm.png";
        var share_big_title = "《庫洛魔法使 回憶鑰匙》";
        var share_min_title = "收集全世界的可愛";
        var share_link = window.location.href;
        outside.share(share_icon, share_big_title, share_min_title, share_link);
    },
    // 阿里云埋点
    countNum: function(data) {
        setTimeout(() => {
            try {
                __bl.sum(data);
            } catch (e) {
                console.log(e)
            }
        }, 500);
    }
}